import { defineStore } from 'pinia';

export const useContentPages = defineStore('page-content', {
    state: () => ({
        statusRegionSelect: false,
        supportShow: false,
        consultationTranslate: { textButton: '', phone: '', phoneText: '' },
        consultForm: {},
        langLoading: false,
    }),
    getters: {
        getDefaultCountry: state => {
            return state.countryList?.find(item => item.id === 1);
        },
        getContactFormData: state => {
            return state.consultForm;
        },
    },
    actions: {
        setConsultationTranslate(title, phone, phoneText) {
            this.consultationTranslate.textButton = title;
            this.consultationTranslate.phone = phone;
            this.consultationTranslate.phoneText = phoneText;
        },
        setContentConsultForm(formParams) {
            this.consultForm = { ...formParams };
        },
        setLangLoading(payload) {
            this.langLoading = payload;
        },
        updateStatusRegionSelect(payload) {
            this.statusRegionSelect = payload;
        },
        updateStatusSupport(payload) {
            this.supportShow = payload;
        },
    },
    persist: true,
});
